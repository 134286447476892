import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components';

import './style.scss';

function AssignmentReturnConfirmationModal({
  isVisible,
  onOverlayClick,
  onClick,
  onClose,
}) {
  return (
    <Modal
      className="tv-assignment-return-confirmation-modal__container"
      hideHeader
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      isVisible={isVisible}
    >
      <p>
        <b className="tv-assignment-return-confirmation-modal__title">
          Önskemål omplanering
        </b>
      </p>
      <p className="tv-assignment-return-confirmation-modal__description">
        Är du säker på att du vill lämna tillbaka ditt uppdrag? Transvoice
        kommer att leta efter en ersättare, vi återkommer om vi lyckas.
      </p>

      <div className="tv-assignment-return-confirmation-modal__button-wrapper">
        <Button
          onClick={onClose}
          className="tv-assignment-return-confirmation-modal__button-wrapper__button"
          label="Gå tillbaka"
          palette="outline"
        />
        <Button
          onClick={onClick}
          className="tv-assignment-return-confirmation-modal__button-wrapper__button"
          label="Ja, fortsätt"
        />
      </div>
    </Modal>
  );
}

AssignmentReturnConfirmationModal.propTypes = {
  isVisible: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};
AssignmentReturnConfirmationModal.defaultProps = {
  isVisible: false,
  onOverlayClick: () => {},
  onClose: () => {},
  onClick: () => {},
};

export default AssignmentReturnConfirmationModal;
